import { OPT_IN_CONTENT_BLOCKER_ALL, isCurrentlyInTransaction } from "../..";
import { MEMORIZE_NATIVE_EVENT_PROPERTY, dispatchInitiatorExecution } from ".";
const OVERWRITE_PROPERTY = "rcbNativeEventListener";
/**
 * Overwrite `window.addEventListener('load')` as they can not be triggered by our script blocker.
 * This can also be used for other known events.
 */ function applyNativeEventListenerInitiator(element, eventName, param) {
    let { onBeforeExecute, isLoad, definePropertySetter } = param === void 0 ? {
        onBeforeExecute: undefined,
        isLoad: false
    } : param;
    const overwriteProp = `${OVERWRITE_PROPERTY}_${eventName}`;
    const memorizeProp = `${MEMORIZE_NATIVE_EVENT_PROPERTY}_${eventName}`;
    // Only overwrite once
    if (element[overwriteProp]) {
        return;
    }
    const { addEventListener } = element;
    if (definePropertySetter) {
        try {
            Object.defineProperty(element, definePropertySetter, {
                set: function(newValue) {
                    if (typeof newValue === "function") {
                        element.addEventListener(eventName, newValue);
                    }
                },
                enumerable: true,
                configurable: true
            });
        } catch (e) {
        // Silence is golden
        }
    }
    Object.assign(element, {
        [overwriteProp]: true,
        addEventListener: function(type) {
            for(var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
                rest[_key - 1] = arguments[_key];
            }
            if (type === eventName) {
                // Redirect to own thread to avoid variable order lacks (e. g. Uncode Gmaps Integration, Contact Form 7
                const executeHandle = ()=>setTimeout(()=>{
                        var _rest_;
                        const afterExecution = dispatchInitiatorExecution({
                            type: "nativeEvent",
                            eventName
                        });
                        onBeforeExecute == null ? void 0 : onBeforeExecute();
                        (_rest_ = rest[0]) == null ? void 0 : _rest_.call(rest, new Event(eventName, {
                            bubbles: true,
                            cancelable: true
                        }));
                        afterExecution();
                    }, 0);
                if (isCurrentlyInTransaction()) {
                    const memorizeExecutionPromise = element[memorizeProp];
                    document.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, (param)=>{
                        let { detail: { load } } = param;
                        if (memorizeExecutionPromise) {
                            memorizeExecutionPromise.then(executeHandle);
                        } else if (isLoad) {
                            load.then(executeHandle);
                        } else {
                            executeHandle();
                        }
                    }, {
                        once: true
                    });
                } else {
                    executeHandle();
                }
            } else {
                addEventListener.apply(this, [
                    type,
                    ...rest
                ]);
            }
        }
    });
}
export { applyNativeEventListenerInitiator };
