/**
 * @see https://stackoverflow.com/a/7557433/5506547
 */ function isElementInViewport(el, partial) {
    if (partial === void 0) partial = false;
    const { top, left, bottom, right, height, width } = el.getBoundingClientRect();
    const { innerWidth, innerHeight } = window;
    if (partial) {
        const vertInView = top <= innerHeight && top + height >= 0;
        const horInView = left <= innerWidth && left + width >= 0;
        return vertInView && horInView;
    } else {
        const { clientHeight, clientWidth } = document.documentElement;
        return top >= 0 && left >= 0 && bottom <= (innerHeight || clientHeight) && right <= (innerWidth || clientWidth);
    }
}
function whenInView(element) {
    return new Promise((resolve)=>{
        const observer = new IntersectionObserver((entries, observer)=>{
            entries.forEach((entry)=>{
                if (entry.isIntersecting) {
                    resolve();
                    observer.unobserve(element); // Stop observing once in view
                }
            });
        }, {
            root: null,
            threshold: 0.1
        });
        observer.observe(element);
    });
}
export { isElementInViewport, whenInView };
