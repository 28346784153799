let currentlyInTransaction = false;
function setCurrentlyInTransaction(state) {
    currentlyInTransaction = state;
}
/**
 * Check if the current page is currently unblocking content.
 */ function isCurrentlyInTransaction() {
    return currentlyInTransaction;
}
export { setCurrentlyInTransaction, isCurrentlyInTransaction };
