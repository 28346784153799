import { HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_COOKIE_IDS, HTML_ATTRIBUTE_GOT_CLICKED, HTML_ATTRIBUTE_GOT_CLICKED_VALUE, HTML_ATTRIBUTE_RESET_PARENT, HTML_ATTRIBUTE_RESET_PARENT_VALUE } from "@devowl-wp/headless-content-unblocker";
/**
 * In some cases it is necessary to reset some styles which are not resettable through
 * the `style` attribute. E.g. pseudo-classes like `::before` and `::after`.
 */ function applyContentBlockRatioCssPlainCss() {
    const element = document.createElement("style");
    element.setAttribute("skip-rucss", "true");
    element.style.type = "text/css";
    const selectorResetParentAttribute = `${HTML_ATTRIBUTE_RESET_PARENT}="${HTML_ATTRIBUTE_RESET_PARENT_VALUE}"`;
    const selectorVisibleContentBlocker = `[${HTML_ATTRIBUTE_BLOCKER_CONNECTED}][${HTML_ATTRIBUTE_COOKIE_IDS}]`;
    const selectorGotClicked = `[${HTML_ATTRIBUTE_GOT_CLICKED}="${HTML_ATTRIBUTE_GOT_CLICKED_VALUE}"]`;
    const selectorContentBlocker = ".rcb-content-blocker";
    const rules = [
        // Completely deactivate ::before pseudo element
        ...[
            // Thrive Architect
            `.thrv_wrapper[${selectorResetParentAttribute}]`,
            // OceanWP
            `.responsive-video-wrap[${selectorResetParentAttribute}]`
        ].map((s)=>`${s}::before{display:none!important;}`),
        // Opacity 0
        ...[
            // WonderPlugin Gallery
            `${selectorContentBlocker}+.wpgridlightbox`
        ].map((s)=>`${s}{opacity:0!important;pointer-events:none!important;}`),
        // Completely hide element
        ...[
            // JetElements for Elementor
            `.jet-video[${selectorResetParentAttribute}]>.jet-video__overlay`,
            // Divi Page Builder
            `.et_pb_video[${selectorResetParentAttribute}]>.et_pb_video_overlay`,
            `${selectorContentBlocker}+div+.et_pb_video_overlay`,
            // Ultimate Video (WP Bakery Page Builder)
            `${selectorContentBlocker}+.ultv-video`,
            // Ultimate Addons for Elementor
            `${selectorContentBlocker}+.elementor-widget-container`,
            // Astra Theme (Gutenberg Block)
            `.wp-block-embed__wrapper[${selectorResetParentAttribute}]>.ast-oembed-container`,
            // WP Grid Builder
            `${selectorContentBlocker}+.wpgb-facet`,
            // tagDiv Composer
            `${selectorContentBlocker}+.td_wrapper_video_playlist`,
            // WP YouTube Lyte
            `${selectorContentBlocker}+div[class^="lyte-"]`,
            // Elementor video image overlay
            `.elementor-fit-aspect-ratio[${selectorResetParentAttribute}]>.elementor-custom-embed-image-overlay`,
            // WP Bakery Salient theme
            `${selectorContentBlocker}+.vc_column-inner`,
            // Bold Page Builder
            `${selectorContentBlocker}+.bt_bb_google_maps`,
            // Oxygen
            `.ou-aspect-ratio[${selectorResetParentAttribute}]>.ou-video-image-overlay`,
            // GoodLayer page builder
            `.gdlr-core-sync-height-pre-spaces:has(+${selectorVisibleContentBlocker})`,
            // Bricks Builder overlay for videos
            `.brxe-video:is(${selectorResetParentAttribute},:has(>${selectorGotClicked}))>[class^='bricks-video-overlay']`
        ].map((s)=>`${s}{display:none!important;}`),
        // Completely disable padding-top
        ...[
            // Gutenberg
            `.wp-block-embed__wrapper[${selectorResetParentAttribute}]::before`,
            // WP Bakery
            `.wpb_video_widget[${selectorResetParentAttribute}] .wpb_video_wrapper`,
            // Astra Theme
            `.ast-oembed-container:has(>${selectorVisibleContentBlocker})`
        ].map((s)=>`${s}{padding-top:0!important;}`),
        // Other rules: If you add a similar one, please merge to an array!
        // Thrive Architect
        `.tve_responsive_video_container[${selectorResetParentAttribute}]{padding-bottom:0!important;}`,
        // Fusion
        `.fusion-video[${selectorResetParentAttribute}]>div{max-height:none!important;}`,
        // Reset height
        ...[
            // https://www.elegantthemes.com/gallery/extra/
            `.widget_video_wrapper[${selectorResetParentAttribute}]`
        ].map((s)=>`${s}{height:auto!important;}`),
        // Reset absolute positioning
        ...[
            // X Pro
            `.x-frame-inner[${selectorResetParentAttribute}]>div.x-video`,
            // Avia Builder
            `.avia-video[${selectorResetParentAttribute}] .avia-iframe-wrap`
        ].map((s)=>`${s}{position:initial!important;}`),
        // Completely disable background
        ...[
            // JetElements for Elementor
            `.jet-video[${selectorResetParentAttribute}]`
        ].map((s)=>`${s}{background:none!important;}`),
        // Completely disable border radius for content blocker within a container which can not be handled correctly
        ...[
            // Thrive Architect
            `.tve_responsive_video_container[${selectorResetParentAttribute}]`
        ].map((s)=>`${s} .rcb-content-blocker > div > div > div {border-radius:0!important;}`),
        // Completely grow the content blocker in a flex box container
        ...[
            // Elementor
            `.elementor-widget-wrap>${selectorVisibleContentBlocker}`,
            // GoodLayer page builder
            `.gdlr-core-sync-height-pre-spaces+${selectorVisibleContentBlocker}`
        ].map((s)=>`${s}{flex-grow:1;width:100% !important;}`),
        `.elementor-background-overlay ~ [${HTML_ATTRIBUTE_COOKIE_IDS}] { z-index: 99; }`
    ];
    element.innerHTML = rules.join("");
    document.getElementsByTagName("head")[0].appendChild(element);
}
export { applyContentBlockRatioCssPlainCss, HTML_ATTRIBUTE_RESET_PARENT, HTML_ATTRIBUTE_RESET_PARENT_VALUE };
