import { matchingSelector } from "@devowl-wp/resolve-none-computed-style";
import { HTML_ATTRIBUTE_VISUAL_PARENT } from "..";
const CHILDREN_SELECTOR_PREFIX = "children:";
/**
 * Find element where the visual content blocker should be placed relative to the blocked element.
 */ function findVisualParent(node, setVisualParentIfClassOfParent) {
    if (setVisualParentIfClassOfParent === void 0) setVisualParentIfClassOfParent = {};
    if (!node.parentElement) {
        return [
            node,
            "none"
        ];
    }
    // Find visual parent attribute
    const forceVisualParent = [
        "a"
    ].indexOf(node.parentElement.tagName.toLowerCase()) > -1; // for links always use the parent
    let useVisualParent = forceVisualParent;
    if (node.hasAttribute(HTML_ATTRIBUTE_VISUAL_PARENT)) {
        useVisualParent = node.getAttribute(HTML_ATTRIBUTE_VISUAL_PARENT);
    } else {
        const { className } = node.parentElement;
        for(const def in setVisualParentIfClassOfParent){
            if (className.indexOf(def) > -1) {
                useVisualParent = setVisualParentIfClassOfParent[def];
                break;
            }
        }
    }
    if (useVisualParent) {
        if (useVisualParent === true || useVisualParent === "true") {
            // Usual behavior, just take the parent
            return [
                node.parentElement,
                "parent"
            ];
        } else if (!isNaN(+useVisualParent)) {
            // The attribute is a number, let's go up x parents
            let parent = node;
            for(let i = 0; i < +useVisualParent; i++){
                if (!parent.parentElement) {
                    return [
                        parent,
                        "parentZ"
                    ]; // Fallback to last found parent
                }
                parent = parent.parentElement;
            }
            return [
                parent,
                "parentZ"
            ];
        } else if (typeof useVisualParent === "string") {
            if (useVisualParent.startsWith(CHILDREN_SELECTOR_PREFIX)) {
                return [
                    node.querySelector(useVisualParent.substr(CHILDREN_SELECTOR_PREFIX.length)),
                    "childrenSelector"
                ];
            } else {
                // The attribute is a query selector
                for(let elem = node; elem; elem = elem.parentElement){
                    if (matchingSelector(elem, useVisualParent)) {
                        return [
                            elem,
                            "parentSelector"
                        ];
                    }
                }
            }
        }
    }
    return [
        node,
        "none"
    ];
}
export { findVisualParent };
