import { isElementInViewport } from "@devowl-wp/react-utils";
import { detectLastClicked, WaitSynchronousScripts, setCurrentlyInTransaction, createVisual, probablyResetParentContainerForVisual, putScriptInlineToDom, findBlockedNodes, transformInlineStyleRules, transformToOriginalAttribute, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_COOKIE_IDS, HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_INLINE, HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE, HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER, OPT_IN_CONTENT_BLOCKER_ALL, applyJQueryReadyInitiator, applyJQueryEventInitiator, applyNativeEventListenerInitiator, setLastClickedConnectedCounter, OPT_IN_CONTENT_BLOCKER, loadVideoSource, delegateClick, dispatchResizeEvent, WAIT_SCRIPTS_SELECTOR_SYNC, WAIT_SCRIPTS_SELECTOR_ASYNC } from "..";
/**
 * Refresh the DOM content depending on acceptance. It covers the following things:
 *
 * - Get all available blocked content
 * - Unblock blocked content depending on acceptance
 * - All other blocked content gets a visual content-blocker (if possible)
 */ async function findAndUnblock(param) {
    let { checker, visual, overwriteAttributeValue, overwriteAttributeNameWhenMatches, transactionClosed, priorityUnblocked, customInitiators, delegateClick: delegateClickSelectors, mode } = param;
    setCurrentlyInTransaction(true);
    const nodes = findBlockedNodes(checker);
    transformInlineStyleRules(checker);
    // A collection of all unblocked content for this "transaction"; so we can keep track a batch
    // of unblocked items to keep dependencies intact (e.g. Custom script is blocked and needs Google Maps
    // API do be available).
    const unblockedNodes = [];
    let foundAnyLastClicked;
    const unmount = (element)=>{
        var _visual_unmount;
        visual == null ? void 0 : (_visual_unmount = visual.unmount) == null ? void 0 : _visual_unmount.call(visual, element);
        probablyResetParentContainerForVisual(element, false);
        element.remove();
    };
    // In some cases, through custom event triggers and unblocked scripts, HTML elements could be "recreated" in our DOM
    // without our changes to mark the DOM node as "complete". Lets find those nodes and mark them correctly.
    document.querySelectorAll(`[${HTML_ATTRIBUTE_BLOCKER_ID}]:not(.rcb-content-blocker):not([${HTML_ATTRIBUTE_COOKIE_IDS}]):not([${HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE}])`).forEach((n)=>n.setAttribute(HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE, "1"));
    // Reset all calculated and memorized results of ratio container as the CSS styles could be changed again (`probablyResetParentContainerForVisual`)
    document.querySelectorAll(`[${HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER}]`).forEach((n)=>n.removeAttribute(HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER));
    let previousPriority;
    let waitAsynchronousScripts;
    for (const row of nodes){
        const { consent, node, isVisualCb, blocker, priority } = row;
        if (consent) {
            if (mode !== "unblock") {
                if (visual && isVisualCb) {
                    visual.busy == null ? void 0 : visual.busy.call(visual, node);
                    continue;
                }
                continue;
            }
            // Got this node already be handled by another call?
            if (!node.hasAttribute(HTML_ATTRIBUTE_COOKIE_IDS)) {
                continue;
            } else if (isVisualCb) {
                unmount(node);
                continue;
            }
            // Allows to execute custom code when a given priority got completed
            if (previousPriority !== undefined && previousPriority !== priority) {
                priorityUnblocked == null ? void 0 : priorityUnblocked(unblockedNodes, previousPriority);
            }
            previousPriority = priority;
            // Immediate deactivate nodes for future unblocks
            node.removeAttribute(HTML_ATTRIBUTE_COOKIE_IDS);
            const connectedBlocker = node.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED);
            const isLastClicked = detectLastClicked(node);
            if (isLastClicked) {
                foundAnyLastClicked = row;
            }
            // Remove visual content blocker if not yet removed through above method
            if (connectedBlocker) {
                const contentBlockers = Array.prototype.slice.call(document.querySelectorAll(`.rcb-content-blocker[consent-blocker-connected="${connectedBlocker}"]`));
                for (const contentBlocker of contentBlockers){
                    unmount(contentBlocker);
                }
                // Also reset parent containers stylings for nodes which not successfully created
                // a visual content blocker (e.g. duplicate exists)
                probablyResetParentContainerForVisual(node, false);
            }
            // Overwrite global listeners so they get immediate executed
            const { ownerDocument } = node;
            const { defaultView } = ownerDocument;
            applyJQueryReadyInitiator(ownerDocument);
            applyJQueryEventInitiator(ownerDocument, defaultView, "load", {
                isLoad: true
            }); // $(window).load()
            applyJQueryEventInitiator(ownerDocument, ownerDocument, "ready"); // $(document).on("ready")
            applyNativeEventListenerInitiator(defaultView, "load", {
                isLoad: true,
                definePropertySetter: "onload"
            });
            applyNativeEventListenerInitiator(ownerDocument, "DOMContentLoaded");
            applyNativeEventListenerInitiator(defaultView, "DOMContentLoaded");
            customInitiators == null ? void 0 : customInitiators(ownerDocument, defaultView);
            const waitSynchronousScripts = new WaitSynchronousScripts(WAIT_SCRIPTS_SELECTOR_SYNC);
            waitAsynchronousScripts = waitAsynchronousScripts || new WaitSynchronousScripts(WAIT_SCRIPTS_SELECTOR_ASYNC);
            // Activate node
            const hasInlineAttribute = node.hasAttribute(HTML_ATTRIBUTE_INLINE);
            const { performedClick, workWithNode } = await transformToOriginalAttribute({
                node,
                allowClickOverrides: hasInlineAttribute ? false : isLastClicked,
                onlyModifyAttributes: hasInlineAttribute,
                setVisualParentIfClassOfParent: visual == null ? void 0 : visual.setVisualParentIfClassOfParent,
                overwriteAttributeValue,
                overwriteAttributeNameWhenMatches
            });
            if (hasInlineAttribute) {
                await putScriptInlineToDom(node);
            } else if (performedClick) {
                // Avoid auto replays between the same transaction
                setLastClickedConnectedCounter(undefined);
            }
            await Promise.all(waitSynchronousScripts.diff());
            // Allow to detach and attach again to DOM so e.g. `MutationObservers` can handle the DOM as expected
            if (workWithNode.getAttribute("consent-redom")) {
                const { parentElement } = workWithNode;
                if (parentElement) {
                    const idx = [
                        ...parentElement.children
                    ].indexOf(workWithNode);
                    parentElement.removeChild(workWithNode);
                    insertChildAtIndex(parentElement, workWithNode, idx);
                }
            }
            workWithNode.dispatchEvent(new CustomEvent(OPT_IN_CONTENT_BLOCKER, {
                detail: {
                    blocker,
                    gotClicked: isLastClicked
                }
            }));
            document.dispatchEvent(new CustomEvent(OPT_IN_CONTENT_BLOCKER, {
                detail: {
                    blocker,
                    element: workWithNode,
                    gotClicked: isLastClicked
                }
            }));
            if (isLastClicked && delegateClickSelectors && delegateClick(workWithNode, delegateClickSelectors)) {
                setLastClickedConnectedCounter(undefined);
            }
            unblockedNodes.push({
                ...row,
                node: workWithNode
            });
        } else if (visual && !isVisualCb) {
            createVisual({
                node,
                blocker: blocker,
                ...visual
            });
        }
    }
    // This transaction is "complete"
    if (unblockedNodes.length) {
        // Definitely reset now our last clicked counter to avoid double auto plays
        if (foundAnyLastClicked) {
            setLastClickedConnectedCounter(undefined);
        }
        // Do this before the events below to keep the initiators intact (e.g. jQuery.fn.ready)
        setCurrentlyInTransaction(false);
        const load = Promise.all(waitAsynchronousScripts.diff());
        document.dispatchEvent(new CustomEvent(OPT_IN_CONTENT_BLOCKER_ALL, {
            detail: {
                unblockedNodes,
                load
            }
        }));
        unblockedNodes.forEach((param)=>{
            let { node } = param;
            node.setAttribute(HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE, "1");
            node.dispatchEvent(new CustomEvent(OPT_IN_CONTENT_BLOCKER_ALL, {
                detail: {
                    unblockedNodes,
                    load
                }
            }));
        });
        // The initiators (e.g. jQuery.ready) are all loaded in a new "thread" with a `setTimeout`,
        // but we need to make sure this event is dispatched afterwards.
        setTimeout(()=>{
            transactionClosed == null ? void 0 : transactionClosed(unblockedNodes);
            loadVideoSource(unblockedNodes);
            dispatchResizeEvent();
            // Scroll to unblocked, clicked element automatically
            if (foundAnyLastClicked) {
                const { node } = foundAnyLastClicked;
                if (!isElementInViewport(node)) {
                    node.scrollIntoView({
                        behavior: "smooth"
                    });
                }
                // Accessibility, make it focusable and focus it
                node.setAttribute("tabindex", "0");
                node.focus({
                    preventScroll: true
                });
            }
        }, 0);
    } else {
        setCurrentlyInTransaction(false);
    }
}
function insertChildAtIndex(container, child, index) {
    if (index >= container.children.length) {
        container.appendChild(child);
    } else {
        container.insertBefore(child, container.children[index]);
    }
}
export { findAndUnblock };
