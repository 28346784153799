import { calculate } from "specificity";
import { priorityByRule } from ".";
function calculateScore(rule, stackSize, property) {
    const score = +calculate(rule.selectorText)[0].specificity.replace(/,/g, "");
    const specificity = stackSize + score * 10 + priorityByRule(rule, property);
    return {
        selector: rule.selectorText,
        specificity
    };
}
export { calculateScore };
