import { isCurrentlyInTransaction, OPT_IN_CONTENT_BLOCKER_ALL } from "../..";
import { MEMORIZE_NATIVE_EVENT_PROPERTY, MEMORIZE_JQUERY_EVENT_PROPERTY, dispatchInitiatorExecution } from ".";
const OVERWRITE_PROPERTY = "rcbJQueryEventListener";
/**
 * Overwrite `jQuery(selector).on` and `jQuery.event.add` for special events
 * like `elementor/frontend/init`.
 */ function applyJQueryEventInitiator(doc, element, eventName, param) {
    let { onBeforeExecute, isLoad } = param === void 0 ? {
        onBeforeExecute: undefined,
        isLoad: false
    } : param;
    const overwriteProp = `${OVERWRITE_PROPERTY}_${eventName}`;
    const memorizeProp = `${MEMORIZE_JQUERY_EVENT_PROPERTY}_${eventName}`;
    const memorizeNativeProp = `${MEMORIZE_NATIVE_EVENT_PROPERTY}_${eventName}`;
    const { jQuery } = doc.defaultView || doc.parentWindow;
    if (!jQuery) {
        return;
    }
    const { event, Event } = jQuery;
    if (!event || !Event || event[overwriteProp]) {
        return;
    }
    const { add } = event;
    Object.assign(event, {
        [overwriteProp]: true,
        add: function() {
            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                args[_key] = arguments[_key];
            }
            var _elem_memorizeNativeProp;
            // https://git.io/JsXSb
            const [elem, types, handler, data, selector] = args;
            const useTypes = Array.isArray(types) ? types : typeof types === "string" ? types.split(" ") : types;
            const memorizeExecutionPromise = event[memorizeProp] || ((_elem_memorizeNativeProp = elem[memorizeNativeProp]) == null ? void 0 : _elem_memorizeNativeProp.then(()=>[]));
            const inTransaction = isCurrentlyInTransaction();
            // Redirect to own thread to avoid variable order lacks (e. g. Uncode Gmaps Integration, Contact Form 7)
            const executeHandle = (param)=>{
                let [, ...eventParameters] = param === void 0 ? [] : param;
                return setTimeout(()=>{
                    const afterExecution = dispatchInitiatorExecution({
                        type: "jQueryEvent",
                        elem,
                        types,
                        handler,
                        data,
                        selector
                    });
                    onBeforeExecute == null ? void 0 : onBeforeExecute(inTransaction);
                    handler == null ? void 0 : handler(new Event(), ...eventParameters);
                    afterExecution();
                }, 0);
            };
            if (types && elem === element) {
                for (const type of useTypes){
                    const isRequestedEventName = type === eventName;
                    if (isRequestedEventName && inTransaction) {
                        document.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, (param)=>{
                            let { detail: { load } } = param;
                            if (memorizeExecutionPromise) {
                                memorizeExecutionPromise.then(executeHandle);
                            } else if (isLoad) {
                                load.then(executeHandle);
                            } else {
                                executeHandle();
                            }
                        }, {
                            once: true
                        });
                    } else if (isRequestedEventName && memorizeExecutionPromise) {
                        memorizeExecutionPromise.then(executeHandle);
                    } else {
                        add.apply(this, [
                            elem,
                            type,
                            handler,
                            data,
                            selector
                        ]);
                    }
                }
            } else {
                add.apply(this, args);
            }
        }
    });
}
export { applyJQueryEventInitiator };
