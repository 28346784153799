/* istanbul ignore file: we do not need to care about the entry point file as errors are detected through integration tests (E2E) */ /**
 * Entrypoint for plain website. This file is loaded on all pages.
 */ import { memorizeJQueryEvent, hijackJqueryFn, hijackJqueryEach, memorizeNativeEvent } from "@devowl-wp/headless-content-unblocker";
import { overwriteElementorVideoApi, hijackElementorOnInit, listenOptInForContentBlocker } from "./others";
import { options } from "preact";
import { ready } from "@devowl-wp/react-utils";
// Needed to be used together with `fastdom`
options.requestAnimationFrame = requestAnimationFrame;
const hijackJqueryFns = [
    "fitVids",
    "mediaelementplayer",
    "prettyPhoto",
    "gMap",
    "wVideo",
    "wMaps",
    "wMapsWithPreload",
    "wGmaps",
    "WLmaps",
    "WLmapsWithPreload",
    "aviaVideoApi",
    // [Plugin Comp] https://gist.github.com/VonStruddle/bbed8109848c84fe2794ae3920473765 (e.g. SuperFly Menu)
    {
        fn: "YTPlayer",
        customBlocked: ()=>window.consentApi.unblock("youtube.com")
    },
    {
        fn: "magnificPopup",
        customBlocked: (element)=>{
            const src = element.getAttribute("src") || element.getAttribute("href");
            const { unblock, unblockSync } = window.consentApi;
            if (unblockSync(src)) {
                return unblock(src, {
                    ref: element,
                    confirm: true
                });
            }
            return undefined;
        }
    },
    // [Plugin Comp] GoodLayer page builder (see https://i.imgur.com/sZhkH3E.png)
    {
        fn: "gdlr_core_parallax_background",
        getElements: (thisArg, elements)=>elements || thisArg,
        callOriginal: (originalFunction, thisArg, param, elements)=>{
            let [, ...rest] = param;
            return originalFunction.apply(thisArg, [
                elements,
                ...rest
            ]);
        }
    },
    // [Plugin Comp] https://www.appthemes.com/themes/vantage/
    "appAddressAutocomplete",
    "appthemes_map"
];
const hijackQueryEach = [
    // https://www.famethemes.com/themes/onepress/
    ".onepress-map",
    // HivePress
    'div[data-component="map"]',
    // Sober theme
    ".sober-map"
];
listenOptInForContentBlocker();
hijackJqueryFn(hijackJqueryFns);
hijackJqueryEach(hijackQueryEach);
overwriteElementorVideoApi();
hijackElementorOnInit([
    {
        className: "Video",
        optIn: ($element, param)=>{
            let { gotClicked } = param;
            if (gotClicked) {
                const settings = $element.data("settings");
                settings.autoplay = true;
                $element.data("settings", settings);
            }
        }
    },
    {
        className: "VideoPlaylistHandler",
        // Delay execution to avoid layout trashing (tab content does not get full height for embedded video)
        delay: 1000
    }
]);
ready(()=>{
    // jQuery fn cannot be ready yet, let's wait for DOM ready
    hijackJqueryFn(hijackJqueryFns);
    hijackJqueryEach(hijackQueryEach);
    memorizeNativeEvent(window, "elementor/frontend/init");
    memorizeJQueryEvent(document, document, "tve-dash.load"); // Thrive Leads
    memorizeJQueryEvent(document, document, "mylisting/single:tab-switched"); // My Listing theme
    memorizeJQueryEvent(document, document, "hivepress:init"); // HivePress
    memorizeJQueryEvent(document, document, "wpformsReady"); // WPForms
}, "interactive");
