/**
 * Get previous HTML element which is visible.
 *
 * @see https://stackoverflow.com/a/31207879/5506547
 * @see https://stackoverflow.com/a/21696585/5506547
 */ function getPreviousVisibleElement(node, rateAsVisible) {
    function prevAll(element) {
        const result = [];
        while(element = element.previousElementSibling)result.push(element);
        return result;
    }
    const previousVisible = prevAll(node).filter((e)=>{
        if (e.offsetParent) {
            // It is safely visible
            return true;
        }
        // Allow to override rating
        return rateAsVisible ? rateAsVisible(e) : false;
    });
    return previousVisible.length ? previousVisible[0] : undefined;
}
export { getPreviousVisibleElement };
