/**
 * Port of `jQuery.parents()`.
 *
 * @param selector Use `undefined` to get all parents
 */ function parents(element, selector, limit) {
    if (limit === void 0) limit = 0;
    const elements = [];
    let elem = element.parentElement;
    const selectorGiven = selector !== undefined;
    let i = 0;
    while(elem !== null){
        const isCorrectNodeType = elem.nodeType === Node.ELEMENT_NODE;
        if (i === 0 && limit === 1 && isCorrectNodeType && selectorGiven) {
            // use native JavaScript function
            const closest = element.closest(selector);
            return closest ? [
                closest
            ] : [];
        }
        if (!selectorGiven || isCorrectNodeType && elem.matches(selector)) {
            elements.push(elem);
        }
        elem = elem.parentElement;
        if (limit !== 0 && elements.length >= limit) {
            break;
        }
        i++;
    }
    return elements;
}
export { parents };
